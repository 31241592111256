import './App.css';

function App() {
    return (
        <div className='wrapper'>
            <img src='/construction.svg' alt='under construction' />
            <h1>Under Construction</h1>
            <div className='subtext'>
                <p>We're currently working on our website</p>
                <p>Please check back later!</p>
            </div>
        </div>
    );
}

export default App;
